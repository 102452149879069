body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6F7F9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root { /* This code changes the background. DO NOT TOUCH */
    --amplify-colors-primary: #F6F7F9;
    --amplify-colors-background-secondary: #F6F7F9;
    --amplify-colors-background-tertiary: #F6F7F9;
    --amplify-colors-background-quaternary: #F6F7F9;
}